import React, { Fragment, useState, useCallback } from 'react'
import { Grid, Paper as MUIPaper, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '_components/button'
import RatingModal from '_components/modal/rating-modal'
import Svg, { ICON } from '_components/svg'
import VisitSchedule from '_components/modal/visit-schedule'
import { UPDATE_APPOINTMENT_DATE_SCHEDULE } from '_modules/service-orders/actions'
import useFetchCall from '_hooks/use-fetch-call'
import useRolePermission from '_hooks/use-role-permission'
import useToggle from '_hooks/use-toggle'

import useStyles from './styles'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'

const PaperInformation = ({ options, className, isEdit, onChange }) => {
  const styles = useStyles()
  const { checkUserPermission } = useRolePermission()

  const [isFocused, onToggleFocus] = useToggle()
  const { isAdmin, isIntermediary } = useRolePermission()
  const [modalSelectProviderDialog, setModalSelectProviderDialog] = useState({
    isOpen: false,
    subTitle: '',
  })
  const [isVisitScheduleModalOpen, toggleVisitScheduleModalOpen] = useToggle()

  const handleEditButtonClick = useCallback(() => {
    checkUserPermission(
      PERMISSIONS.SERVICE_ORDER,
      [PERMISSIONS_ACTIONS.ADD, PERMISSIONS_ACTIONS.EDIT],
      toggleVisitScheduleModalOpen
    )
  }, [toggleVisitScheduleModalOpen])

  const handleUpdateAppointmentSuccess = useCallback(() => {
    if (isVisitScheduleModalOpen) {
      toggleVisitScheduleModalOpen()
    }
  }, [isVisitScheduleModalOpen])

  useFetchCall(UPDATE_APPOINTMENT_DATE_SCHEDULE.ACTION, handleUpdateAppointmentSuccess)

  return (
    <MUIPaper
      className={classnames(styles.paper, { [styles.focusedPared]: isFocused }, className)}
      variant="outlined"
    >
      {options.map(
        ({ value, label, hasCheckMoreButton, hasCheckEditButton, hasEditButton, toggleModal }) => {
          function handleChangeToggle() {
            checkUserPermission(
              PERMISSIONS.SERVICE_ORDER,
              [PERMISSIONS_ACTIONS.ADD, PERMISSIONS_ACTIONS.EDIT],
              toggleModal
            )
          }

          return (
            <Fragment key={label}>
              <Grid container direction="column" justify="center">
                <Typography className={styles.label} variant="body2">
                  {label}
                </Typography>
                {value.map(option => {
                  let optionValue =
                    option && typeof option === 'object' && 'value' in option
                      ? option.value
                      : option
                  const name =
                    option && typeof option === 'object' && 'name' in option ? option.name : ''

                  optionValue =
                    typeof option === 'boolean'
                      ? optionValue === true
                        ? 'Sim'
                        : 'Não'
                      : optionValue

                  if (label === 'Cliente possui outro orçamento') {
                    optionValue = optionValue === 'yes' ? 'Sim' : 'Não'
                  }

                  return isEdit ? (
                    <textarea
                      key={name}
                      value={optionValue}
                      onChange={onChange}
                      className={styles.input}
                      onFocus={onToggleFocus}
                      onBlur={onToggleFocus}
                      name={name}
                      rows={5}
                    />
                  ) : (
                    <Typography
                      key={optionValue}
                      className={
                        label === 'Diagnóstico do problema' || label === 'Solução do problema'
                          ? classnames(styles.valueBreak, {
                              [styles.smaller]: optionValue?.length > 100,
                            })
                          : classnames(styles.value, {
                              [styles.smaller]: optionValue?.length > 100,
                            })
                      }
                      component="p"
                      variant="h5"
                    >
                      {optionValue}
                    </Typography>
                  )
                })}
              </Grid>
              {hasCheckMoreButton && (
                <Button
                  className={styles.button}
                  color="primary"
                  endIcon={<Svg className={styles.icon} type={ICON.EDIT} />}
                  onClick={handleChangeToggle}
                >
                  Editar
                </Button>
              )}
              {hasEditButton && (
                <Button
                  className={styles.button}
                  color="primary"
                  endIcon={<Svg className={styles.icon} type={ICON.EDIT} />}
                  onClick={handleChangeToggle}
                >
                  Editar
                </Button>
              )}
              {hasCheckEditButton && (isAdmin || isIntermediary) && (
                <Button
                  className={styles.button}
                  color="primary"
                  endIcon={<Svg className={styles.icon} type={ICON.EDIT} />}
                  onClick={handleEditButtonClick}
                >
                  Editar
                </Button>
              )}
              <RatingModal
                ratingModal={modalSelectProviderDialog}
                setRatingModal={setModalSelectProviderDialog}
              />
              {isVisitScheduleModalOpen && <VisitSchedule onClose={toggleVisitScheduleModalOpen} />}
            </Fragment>
          )
        }
      )}
    </MUIPaper>
  )
}

PaperInformation.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])),
    })
  ).isRequired,
  className: PropTypes.string,
  isEdit: PropTypes.bool,
  onChange: PropTypes.func,
}

PaperInformation.defaultProps = {
  className: '',
  isEdit: false,
  onChange: () => {},
}

export default React.memo(PaperInformation)
