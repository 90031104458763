/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { Grid, Typography, Divider, CircularProgress } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { Preview, useConfirm, Button } from '@refera/ui-web'
import { Attachments } from '_/components/refera-ui'
import { Redirect, useNavigate } from '@reach/router'

import useToggle from '_hooks/use-toggle'
import Loading from '_components/loading'
import FormEditRequester from '_/components/modal/form-edit-requester'
import { getCategoriesLoadingSelector } from '_modules/categories/selectors'
import { getTradesmanSequenceResults } from '_modules/tradesman-sequence/selectors'
import { getTradesmanSequence } from '_modules/tradesman-sequence/actions'
import TextField from '_components/textfield'
import useHandleInput from '_hooks/use-handle-input'
import { PROPERTY_VALUES, STAGE_TYPES } from '_utils/constants'
import { BUTTONS_ACTION, STEP_STATUS } from '_utils/constants/service-order'
import { CONTACT_FIELDS } from '_utils/user'
import {
  updateServiceOrder,
  deleteAttachment,
  verifyNewBudget,
  getActiveBudget,
} from '_modules/service-orders/actions'
import {
  parseNumber,
  phoneNumberMaskServiceOrder,
  removeDuplicatedObjectFromArray,
} from '_utils/helpers'
import ViewOriginalDescriptionModal from '_components/modal/view-original-description-modal'
import ApprovedBudgetInfo from '_views/service-order/steps/request/approved-budget-info'
import ServiceApproval from '_views/service-order/steps/request/service-approval'
import useCanDoButtonAction from '_hooks/use-can-do-button-action'
import useRolePermission from '_hooks/use-role-permission'
import {
  activeBudgetSelector,
  currentServiceOrderSelector,
} from '_modules/service-orders/selectors'
import ModalDialog, { WARNING_MODAL } from '_components/modal/modal-dialog'
import { getStepStatusLogSelector } from '_modules/budget/selectors'
import ConfirmRemoveDialog from '_components/dialogs/ConfirmRemoveDialog'
import PropertyDataModal from '_components/modal/property-data-modal'
import SequenceModal from '_components/modal/sequence-modal'
import { userSelector } from '_modules/authentication/selectors'
import { getAgency } from '_modules/agency/actions'

import PaperInformation from '../../paper-information'
import { PAPER } from '../../constants'
import EditMode from '../../edit-mode'
import OpenBudgetNotify from './open-budget-notify'
import useStyles from './styles'
import ExecutionBudget from './execution-budget'
import moment from 'moment'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import { useToast } from '_/hooks/use-toast'

const defaultPreviewState = {
  visible: false,
  selectedIndex: undefined,
}

const FIELDS_LISTING = [
  PROPERTY_VALUES.CEP,
  PROPERTY_VALUES.ADDRESS,
  PROPERTY_VALUES.NUMBER,
  PROPERTY_VALUES.CITY,
  PROPERTY_VALUES.NEIGHBORHOOD,
  PROPERTY_VALUES.EXTRA_ADDRESS_INFO,
  PROPERTY_VALUES.CODE,
]

const FIELDS_CLIENT = [
  CONTACT_FIELDS.CLIENT_TYPE,
  CONTACT_FIELDS.CONTACT_CPF_CNPJ,
  CONTACT_FIELDS.CONTACT_EMAIL,
  CONTACT_FIELDS.CONTACT_NAME,
  CONTACT_FIELDS.CONTACT_NUMBER,
  CONTACT_FIELDS.FIRST_SUGGESTION_DATE,
  CONTACT_FIELDS.FIRST_START_TIME,
  CONTACT_FIELDS.FIRST_END_TIME,
  CONTACT_FIELDS.SECOND_SUGGESTION_DATE,
  CONTACT_FIELDS.SECOND_START_TIME,
  CONTACT_FIELDS.SECOND_END_TIME,
  CONTACT_FIELDS.THIRD_SUGGESTION_DATE,
  CONTACT_FIELDS.THIRD_START_TIME,
  CONTACT_FIELDS.THIRD_END_TIME,
]

const RequestStep = ({ className }) => {
  const dispatch = useDispatch()
  const { isAdmin, isTradesman, isSAAS, checkUserPermission } = useRolePermission()
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const stepStatusLog = useSelector(getStepStatusLogSelector)
  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })
  const [dialogMessage, setDialogMessage] = useState('')
  const user = useSelector(userSelector)
  const [agency, setAgency] = useState()
  const approvedBudgetSelector = useSelector(activeBudgetSelector)
  const { showToast } = useToast()

  useEffect(() => {
    dispatch(getActiveBudget(serviceOrder.id))
    dispatch(getAgency(user?.agency)).then(res => setAgency(res))
  }, [serviceOrder.id])

  const approvedBudget = useMemo(() => {
    const logResult = stepStatusLog.filter(
      log =>
        log.budgetId === serviceOrder.activeBudget &&
        (log.stepStatus === 'waiting_service_schedule' ||
          log.stepStatus === 'allocate_tradesman_to_pre_approved_proposal')
    )

    if (logResult.length > 0) {
      const { budgetId } = logResult[0]

      return serviceOrder?.budget.toJS().filter(budget => budget.id === budgetId)[0]
    }

    return null
  }, [serviceOrder, stepStatusLog])

  const tradesmanSequenceResults = useSelector(getTradesmanSequenceResults)
  const canSelectTradesman = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.SELECT_TRADESMAN,
  })
  const canSelectTradesmanInnerBudget = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.ALLOCATE_TRADESMAN_INNER_BUDGET,
  })
  const [description, handleDescription, setDescription] = useHandleInput(serviceOrder?.description)
  const [editMode, setEditMode] = useToggle()
  const [isEditClient, handleClientModal] = useToggle()
  const [isEditListing, handleListingModal] = useToggle()
  const [showOriginalDescription, toggleOriginalDescription] = useToggle()
  const [editAttachments, toggleEditAttachments] = useToggle()
  const [openBudget, handleOpenBudget] = useToggle()
  const [sequenceModal, setSequenceModal] = useState({ isOpen: false, sequenceId: null, name: '' })

  const [attachments, setAttachments] = useState([])

  const attachmentsPdf = useMemo(
    () =>
      attachments.filter(file =>
        file instanceof File ? file.type === 'application/pdf' : file.file.indexOf('.pdf') !== -1
      ),
    [attachments]
  )

  const attachmentsImages = useMemo(
    () =>
      attachments.filter(file =>
        file instanceof File ? file.type !== 'application/pdf' : file.file.indexOf('.pdf') === -1
      ),
    [attachments]
  )

  const isServicesLoading = useSelector(getCategoriesLoadingSelector)
  const styles = useStyles()
  const { isConfirmed } = useConfirm()
  const [previewState, setPreviewState] = useState(defaultPreviewState)
  const [toDelete, setToDelete] = useState([])
  /**
   * @description Remove as imagens do componente de anexos (Imagens, Vídeos e PDF)
   * @param {number} index Indice passado pelo componente de anexo a ser deletado
   * @param {boolean} pdf Verifica se a função foi chamada do componente de Arquivos PDF
   */

  const showHasAnotherBudget = useMemo(() => {
    const orderAgency = serviceOrder?.agency

    return orderAgency.get('hasAnotherBudgetField') && (isAdmin || isSAAS)
  }, [serviceOrder?.agency, isAdmin, isSAAS])

  const fetchImagesFromS3 = async () => {
    return (
      serviceOrder?.attachments
        ?.toArray()
        .filter(file => file.get('fileType') === 'pictures_order')
        .map(file => ({ file: file?.get('file'), id: file?.get('id') }))
        ?.reverse() || []
    )
  }

  const handleReloadImages = async () => {
    try {
      const imagesFromS3 = await fetchImagesFromS3()
      if (imagesFromS3?.length === 0 && !editAttachments) {
        return showToast({
          type: 'info',
          message: 'Este registro não possui anexos.',
        })
      }
      return setAttachments(imagesFromS3)
    } catch (error) {
      return console.error('Erro ao recarregar imagens do S3:', error)
    }
  }

  const handleRemoveAttachments = useCallback(
    async (index, pdf) => {
      setDialogMessage('Você deseja deletar este item?')
      const confirmed = await isConfirmed()
      if (confirmed) {
        const currentFile = pdf ? attachmentsPdf[index] : attachmentsImages[index]
        if (currentFile?.id) {
          setToDelete(prev => [...prev, currentFile.id])
          setAttachments(old => old.filter(current => current.id !== currentFile.id))
        } else {
          setAttachments(old =>
            old.filter(current => current.lastModified !== currentFile?.lastModified)
          )
        }
      }
    },
    [attachments, attachmentsImages, setDialogMessage, isConfirmed]
  )
  const clientValues = useMemo(
    () => ({
      ...FIELDS_CLIENT.reduce(
        (acc, current) => ({ ...acc, [current]: serviceOrder[current] || '' }),
        {}
      ),
      clientType: serviceOrder?.clientType,
      firstContactSecondary: serviceOrder.firstContactSecondary
        ? `+${parseNumber(serviceOrder.firstContactSecondary)}`
        : '',
      secondContactSecondary: serviceOrder.secondContactSecondary
        ? `+${parseNumber(serviceOrder.secondContactSecondary)}`
        : '',
    }),
    [serviceOrder]
  )

  const propertyValues = useMemo(
    () =>
      FIELDS_LISTING.reduce(
        (acc, current) => ({ ...acc, [current]: serviceOrder.getIn(['property', current]) || '' }),
        { id: serviceOrder.get('propertyCode') }
      ),
    [serviceOrder]
  )

  const budgets = useMemo(() => {
    const filteredBudgets = serviceOrder
      ?.toJS()
      .budget?.filter(budget => !budget?.isOriginal && budget !== undefined)

    if (!filteredBudgets?.length) {
      return null
    }

    const budgetsWithoutDuplicates = removeDuplicatedObjectFromArray(filteredBudgets, 'id')

    return isAdmin || isSAAS
      ? budgetsWithoutDuplicates?.filter(
          budget => budget.released || (budget.proposeOpened && budget.diagnosis)
        )
      : budgetsWithoutDuplicates?.filter(
          budget => budget.isRevised || (budget.proposeOpened && budget.diagnosis)
        )
  }, [isAdmin, serviceOrder, isSAAS])

  const activeBudgetHasNoPayerData = useMemo(() => {
    const activeBudget = budgets?.find(budget => budget?.id === serviceOrder?.activeBudget)
    const budgetPayers = activeBudget?.budgetPayer

    const hasNoPayer = budgetPayers?.length === 0

    const isPayerDataEmpty = budgetPayers?.some(
      payer =>
        payer.responsibleName === '' ||
        payer.whatsappResponsible === '' ||
        payer.paymentResponsible === '' ||
        payer.responsibleDocNumber === '' ||
        payer.nameInvoice === '' ||
        payer.formOfPayment === '' ||
        payer.payCollector === '' ||
        payer.installmentQuantity === '' ||
        payer.recipientInvoice === '' ||
        payer.personPayingEmail === ''
    )

    return hasNoPayer || isPayerDataEmpty
  }, [budgets])

  const handleContactNumber = useMemo(() => {
    if (serviceOrder.secondContactSecondary) {
      return `${phoneNumberMaskServiceOrder(
        serviceOrder.contactNumber
      )}, ${phoneNumberMaskServiceOrder(
        serviceOrder.firstContactSecondary
      )}, ${phoneNumberMaskServiceOrder(serviceOrder.secondContactSecondary)}`
    }
    if (serviceOrder.firstContactSecondary) {
      return `${phoneNumberMaskServiceOrder(
        serviceOrder.contactNumber
      )}, ${phoneNumberMaskServiceOrder(serviceOrder.firstContactSecondary)}`
    }
    return phoneNumberMaskServiceOrder(serviceOrder.contactNumber)
  }, [
    serviceOrder?.contactNumber,
    serviceOrder?.firstContactSecondary,
    serviceOrder?.secondContactSecondary,
  ])

  const renderPapers = useMemo(
    () =>
      PAPER.map(paper =>
        paper
          .filter(options => options.step === STAGE_TYPES.ORDER)
          .map(options => {
            if (options?.isUserNameValue) {
              return {
                ...options,
                value: [serviceOrder.contactName, handleContactNumber],
                toggleModal: handleClientModal,
              }
            }
            if (options?.isPropertyValue) {
              if (options?.hasCheckMoreButton) {
                return {
                  ...options,
                  toggleModal: handleListingModal,
                  value: [`${serviceOrder.getIn(['property', 'code'])}`],
                }
              }
              if (options?.contractStartDate) {
                return {
                  ...options,
                  value: [serviceOrder.contractStartDate],
                }
              }
              return {
                ...options,
                value: [
                  `${serviceOrder.getIn(['property', 'neighborhood'])}, ${serviceOrder.getIn([
                    'property',
                    'city',
                  ])}`,
                ],
              }
            }
            if (options?.name) {
              if (options?.name[0] === 'hasAnotherBudget' && !showHasAnotherBudget) {
                return null
              }
              return {
                ...options,
                value: [options?.name?.reduce((acc, current) => acc?.get?.(current), serviceOrder)],
              }
            }
            if (options?.isDate) {
              return {
                ...options,
                value: [
                  serviceOrder?.visitSchedule
                    ? moment(serviceOrder?.visitSchedule).format('DD/MM/YYYY, [às] HH:mm')
                    : 'Não Agendado',
                ],
              }
            }
            if (options?.code) {
              return {
                ...options,
                value: [serviceOrder?.externalId ? serviceOrder?.externalId : 'Não informado'],
              }
            }
            return options
          })
          .filter(Boolean)
      ),
    [handleClientModal, handleListingModal, serviceOrder, showHasAnotherBudget]
  )

  const handleCancelAttachments = useCallback(() => {
    setAttachments(prevState => prevState.filter(item => item.id !== undefined))
    toggleEditAttachments()
  }, [toggleEditAttachments])

  const [picturesLoading, setPicturesLoading] = useState(false)

  useEffect(() => {
    if (picturesLoading === true) {
      setTimeout(() => {
        setPicturesLoading(false)
      }, 3000)
    }
  }, [picturesLoading])

  useEffect(() => {
    if (editAttachments) handleReloadImages()
  }, [editAttachments])

  useEffect(() => {
    dispatch(getTradesmanSequence(serviceOrder?.id))
  }, [serviceOrder, dispatch])

  const [isSaveClientLoading, setIsSaveClientLoading] = useState(false)

  const handleSaveAttachments = () => {
    setPicturesLoading(prevState => !prevState)
    setIsSaveClientLoading(true)

    const newAttachments = attachments.filter(item => item?.id === undefined)

    dispatch(
      updateServiceOrder({
        attachments: newAttachments,
      })
    )
      .then(() => {
        handleSuccess()
        if (toDelete.length > 0) {
          toDelete.map(fileID => dispatch(deleteAttachment(fileID)))
          toggleEditAttachments()
          setToDelete([]) // RESETS ARRAY TO AVOID UNECESSARY DELETE REQUESTS ON FUTURE SAVE ATTEMPTS
        }
        setPicturesLoading(prevState => !prevState)
        setAttachments(
          serviceOrder?.attachments
            ?.toArray()
            .map(file => ({ file: file?.get('file'), id: file?.get('id') }))
            ?.reverse()
        )
      })
      .catch(() => {})
      .finally(() => setIsSaveClientLoading(false))
  }

  const handleSaveDescription = useCallback(() => {
    dispatch(updateServiceOrder({ description }))
  }, [description, dispatch])

  const handleCancelDescription = useCallback(() => {
    setEditMode()
    setDescription(serviceOrder?.description)
  }, [serviceOrder?.description, setDescription, setEditMode])

  const handleSuccess = useCallback(() => {
    if (editMode) {
      setEditMode()
    }
    setAttachments(
      serviceOrder?.attachments
        ?.toArray()
        .map(file => ({ file: file?.get('file'), id: file?.get('id') }))
        ?.reverse()
    )
    if (editAttachments) {
      toggleEditAttachments()
    }
  }, [
    editMode,
    isEditClient,
    serviceOrder?.attachments,
    setEditMode,
    editAttachments,
    toggleEditAttachments,
  ])

  const PreviewComponent = useMemo(
    () => (
      <Preview
        open={previewState.visible}
        onClose={() => setPreviewState(() => defaultPreviewState)}
        selectedIndex={previewState.selectedIndex}
        images={
          attachmentsImages &&
          attachmentsImages.map((item, index) =>
            item.id
              ? {
                  id: item.id,
                  url: item.file,
                }
              : {
                  id: index,
                  url: URL.createObjectURL(item),
                }
          )
        }
      />
    ),
    [previewState, attachments]
  )

  const handleOpenSequenceModal = useCallback(
    ({
      target: {
        dataset: { sequenceid: sequenceId },
        name,
      },
    }) => {
      setSequenceModal({ isOpen: true, sequenceId, name })
    },
    [tradesmanSequenceResults]
  )

  const handleCloseSequenceModal = useCallback(() =>
    setSequenceModal({ isOpen: false, sequenceId: null, name: '' })
  )

  const renderSequenceButtons = useMemo(() => {
    if (!tradesmanSequenceResults) {
      return null
    }

    const sortedResults = tradesmanSequenceResults.slice().sort((a, b) => b?.id - a?.id)

    return (
      <>
        {sortedResults.slice().map((sequence, index) => (
          <Button
            color="primary"
            variant="primary"
            key={sequence?.id ?? index}
            data-sequenceID={sequence?.id}
            to={`orcamento/${sequence?.id}`}
            name={`Sequência ${sortedResults.length - index}`}
            className={styles.button}
            style={{ marginRight: '8px' }}
            onClick={handleOpenSequenceModal}
          >
            Sequência {sortedResults.length - index}
          </Button>
        ))}
      </>
    )
  }, [tradesmanSequenceResults, serviceOrder])

  const navigate = useNavigate()
  function redirectUrl(url) {
    navigate(url)
  }

  const renderBudgetsButton = useMemo(() => {
    if (!budgets) {
      return null
    }

    return (
      <>
        <Divider className={styles.divider} />
        {budgets
          .slice()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map((budget, index) => (
            <Button
              spacing={8}
              color={budget.refused ? 'red' : 'primary'}
              variant="primary"
              key={budget?.id ?? index}
              id={budget?.id}
              onClick={() => redirectUrl(`${serviceOrder?.id}/orcamento/${budget?.id}`)}
              className={styles.button}
            >
              Orçamento {budgets.length - index}
            </Button>
          ))}
        <Divider className={styles.divider} />
      </>
    )
  }, [budgets, serviceOrder])

  const renderApprovedBudget = useMemo(() => {
    if (!serviceOrder || !approvedBudget) {
      return null
    }

    return (
      <>
        <ApprovedBudgetInfo serviceOrder={serviceOrder} />
        {serviceOrder?.datetimeExecutionScheduled && (
          <>
            <Divider />
            <ExecutionBudget serviceOrder={serviceOrder} />
          </>
        )}
        <Divider className={styles.divider} />
        <ServiceApproval serviceOrder={serviceOrder} />
      </>
    )
  }, [serviceOrder, approvedBudget, stepStatusLog])

  const onOpenAddProvider = useCallback(async () => {
    if (!canSelectTradesman) {
      setModalDialog({
        isOpen: true,
        subTitle: 'O chamado não está em uma etapa, que permite seleção de prestadores.',
        type: WARNING_MODAL,
      })
      return
    }

    if (
      serviceOrder?.hasProposeOpened &&
      serviceOrder?.stepStatus !== STEP_STATUS.INNER_BUDGET_REFUSED
    ) {
      if (activeBudgetHasNoPayerData) {
        setModalDialog({
          isOpen: true,
          subTitle:
            'Você não pode selecionar um prestador para um orçamento interno que não possui os dados do pagador. Preencha os dados do pagador e tente novamente.',
          type: WARNING_MODAL,
        })
        return
      }

      if (!canSelectTradesmanInnerBudget && !stepStatusLog?.status === 'inner_budget_refused') {
        setModalDialog({
          isOpen: true,
          subTitle: 'O chamado não está em uma etapa, que permite seleção de prestadores.',
          type: WARNING_MODAL,
        })
        return
      }

      setDialogMessage(
        'Este chamado possui um orçamento interno e esta ação vai enviar tal orçamento para o prestador. Você confirma esta ação?'
      )
      const confirmed = await isConfirmed()
      if (confirmed === false) return
    }

    if (serviceOrder?.id) {
      const checkPermissionName = approvedBudgetSelector
        ? PERMISSIONS.SELECT_TRADESMAN_TO_PREAPPROVED_PROPOSAL
        : PERMISSIONS.SELECT_TRADESMAN

      checkUserPermission(checkPermissionName, PERMISSIONS_ACTIONS.ADD, () =>
        dispatch(verifyNewBudget(serviceOrder?.id))
      )
    }
  }, [
    activeBudgetHasNoPayerData,
    canSelectTradesmanInnerBudget,
    canSelectTradesman,
    stepStatusLog,
    serviceOrder?.id,
    serviceOrder?.hasProposeOpened,
    stepStatusLog?.status,
    setDialogMessage,
    approvedBudgetSelector,
  ])

  const handleSelectTradesman = useCallback(() => {
    const hasCategory = serviceOrder?.category?.first()
    const hasPriority = serviceOrder?.priority

    if (serviceOrder?.tradesman) {
      onOpenAddProvider()
      return
    }

    if (!hasCategory || !hasPriority) {
      const subTitle = `Selecione antes a ${!hasCategory ? 'categoria' : 'prioridade'} do chamado.`

      setModalDialog({
        isOpen: true,
        subTitle,
        type: WARNING_MODAL,
      })
      return
    }

    onOpenAddProvider()
  }, [onOpenAddProvider, serviceOrder?.category, serviceOrder?.priority, serviceOrder?.tradesman])

  if (isServicesLoading && !serviceOrder && !renderBudgetsButton?.length && !agency) {
    return <Loading />
  }

  if (isTradesman) return <Redirect noThrow to="/link-expirado" />

  return (
    <Grid className={className}>
      {dialogMessage && <ConfirmRemoveDialog message={dialogMessage} />}
      {PreviewComponent}
      <Grid className={styles.paperWrapper}>
        {renderPapers.map(options => (
          <PaperInformation key={options[0].id} options={options} />
        ))}
      </Grid>
      <Grid className={styles.descriptionWrapper}>
        <Grid className={styles.descriptionLabel}>
          <Typography variant="h5" component="p" className={styles.descriptionTitle}>
            Descrição do chamado
          </Typography>
          <Grid>
            <Button
              className={styles.originalDescriptionButton}
              color="primary"
              endIcon={<VisibilityIcon className={styles.visibilityIcon} />}
              onClick={toggleOriginalDescription}
            >
              Ver original
            </Button>
            <EditMode
              editMode={editMode}
              setEditMode={setEditMode}
              handleSave={handleSaveDescription}
              handleCancel={handleCancelDescription}
              isLoading={editMode && isSaveClientLoading}
            />
          </Grid>
        </Grid>
        <TextField
          className={styles.input}
          value={description}
          variant="outlined"
          multiline
          onChange={handleDescription}
          disabled={!editMode || isSaveClientLoading}
        />
      </Grid>
      <Grid className={styles.descriptionWrapper}>
        <Grid container justify="space-between">
          <Typography variant="h5" component="p" className={styles.descriptionTitle}>
            Anexos
          </Typography>
          <EditMode
            editMode={editAttachments}
            setEditMode={toggleEditAttachments}
            handleSave={handleSaveAttachments}
            handleCancel={handleCancelAttachments}
            isLoading={picturesLoading}
          />
        </Grid>
        {picturesLoading ? (
          <Grid container justify="center" alignItems="center" className={styles.loading}>
            <CircularProgress size={40} />
          </Grid>
        ) : (
          <>
            <Button onClick={handleReloadImages}>Carregar Anexos</Button>
            {(attachments?.length > 0 || editAttachments) && (
              <>
                <Attachments
                  label="Imagens e vídeos"
                  downloadable={!editAttachments}
                  readOnly={!editAttachments}
                  files={attachmentsImages || []}
                  accept={{
                    'image/jpeg': [
                      '.jpeg',
                      '.png',
                      '.jpg',
                      '.bmp',
                      '.webp',
                      '.mkv',
                      '.mp4',
                      '.mov',
                      '.avi',
                      '.m4v',
                      '.mpg',
                      '.mpeg',
                      '.wmv',
                      '.webm',
                    ],
                  }}
                  onDrop={files => setAttachments(old => [...old, ...files])}
                  onRemoveItem={index => handleRemoveAttachments(index, false)}
                  onItemClick={index => {
                    setPreviewState(() => ({
                      visible: true,
                      selectedIndex: index,
                    }))
                  }}
                />
                <Attachments
                  label="Arquivos PDF"
                  content="files"
                  downloadable={!editAttachments}
                  readOnly={!editAttachments}
                  files={attachmentsPdf || []}
                  multiple={false}
                  accept={{
                    'application/pdf': ['.pdf'],
                  }}
                  onDrop={files => setAttachments(old => [...old, ...files])}
                  onRemoveItem={index => handleRemoveAttachments(index, true)}
                  onItemClick={index => window.open(attachmentsPdf[index].file)}
                />
              </>
            )}
          </>
        )}
      </Grid>
      <Divider className={styles.divider} />
      <Grid>
        {(isAdmin || isSAAS) && renderSequenceButtons}

        <Button
          style={{ marginRight: '8px' }}
          onClick={handleSelectTradesman}
          color="primary"
          variant="primary"
        >
          Selecionar prestador
        </Button>

        {renderBudgetsButton}
      </Grid>
      {(isAdmin || isSAAS) && (
        <SequenceModal
          isOpen={sequenceModal.isOpen}
          sequenceId={sequenceModal.sequenceId}
          onClose={handleCloseSequenceModal}
        />
      )}

      <Grid>
        {renderApprovedBudget}

        {isEditClient && (
          <FormEditRequester open onCancel={handleClientModal} user={clientValues} />
        )}
        {openBudget && <OpenBudgetNotify handleModal={handleOpenBudget} />}
        {isEditListing && (
          <PropertyDataModal
            open={isEditListing}
            onCloseModal={handleListingModal}
            data={propertyValues}
            serviceOrder={serviceOrder}
            agency={agency}
          />
        )}
        {showOriginalDescription && (
          <ViewOriginalDescriptionModal
            handleModal={toggleOriginalDescription}
            originalDescription={serviceOrder?.originalDescription}
          />
        )}
        <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />
      </Grid>
    </Grid>
  )
}

RequestStep.propTypes = {
  className: PropTypes.string,
}

RequestStep.defaultProps = {
  className: '',
}

export default React.memo(RequestStep)
